import { USER_ROLES } from '@acin/user-accounts';
import { MainNavMenuItem } from '@acin/ui-core';
import { SecondLevelNavItem } from '@acin/ui-core/types/src/components/MainNavHorizontal/components/MainNavHorizontal';

export interface AppMenuItem extends MainNavMenuItem {
    permissions?: string[];
}

export const menu = (accountCode: string, reportCategories?: SecondLevelNavItem[]): AppMenuItem[] => {
    return [
        {
            id: 'controls',
            permissions: [USER_ROLES.ACIN_USER, USER_ROLES.CUSTOMER_USER, USER_ROLES.DATA_USER, USER_ROLES.SUPER_ADMIN],
            label: `${accountCode.slice(-4)} Controls`,
        },
        {
            id: 'controlQuality',
            permissions: [USER_ROLES.ACIN_USER, USER_ROLES.CUSTOMER_USER, USER_ROLES.DATA_USER, USER_ROLES.SUPER_ADMIN],
            label: `${accountCode.slice(-4)} Control Quality`,
        },
        {
            id: 'acinIndexControls',
            permissions: [USER_ROLES.ACIN_USER, USER_ROLES.CUSTOMER_USER, USER_ROLES.DATA_USER, USER_ROLES.SUPER_ADMIN],
            label: 'Acin Index Controls',
        },
        {
            id: 'calibrate',
            permissions: [USER_ROLES.ACIN_USER, USER_ROLES.CUSTOMER_USER, USER_ROLES.DATA_USER, USER_ROLES.SUPER_ADMIN],
            label: 'Calibrate to Industry',
        },
        {
            id: 'riskIntelligenceHub',
            permissions: [
                USER_ROLES.ACIN_USER,
                USER_ROLES.CUSTOMER_USER,
                USER_ROLES.DATA_USER,
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.RISK_INTEL_USER,
            ],
            label: 'Risk Intelligence',
        },
        {
            id: 'connectData',
            permissions: [USER_ROLES.SUPER_ADMIN, USER_ROLES.DATA_USER],
            label: 'Connect Data',
        },
        {
            id: 'manageAccounts',
            label: 'Manage Accounts',
            permissions: [USER_ROLES.SUPER_ADMIN],
        },
    ];
};

export const getMenuItems = (
    accountCode: string,
    permissions?: string[],
    reportCategories?: SecondLevelNavItem[],
): MainNavMenuItem[] => {
    if (!permissions) return [];

    const items = menu(accountCode, reportCategories).filter((item) =>
        item.permissions?.some((itemPermissions) => permissions.includes(itemPermissions)),
    );

    return items.map((item) => ({ id: item.id, label: item.label, secondLevelNavigation: item.secondLevelNavigation }));
};

export const qualityToolsNavigationMap = new Map([
    ['check-control-quality', '/control-quality/check-control-quality'],
    ['rewrite-control', '/control-quality/rewrite-control'],
    ['process-to-control', '/control-quality/process-to-control'],
    ['procedure-to-control', '/control-quality/procedure-to-control'],
    ['policy-to-control', '/control-quality/policy-to-control'],
    ['control-to-key-control-indicator', '/control-quality/control-to-key-control-indicator'],
    ['control-to-testing-scripts', '/control-quality/control-to-testing-scripts'],
]);

const routingMap = new Map([
    ['dashboard', '/'],
    ['calibrate', '/calibrate'],
    ['controls', '/controls'],
    ['controlQuality', '/control-quality'],
    ['acinIndexControls', '/acin-index-controls'],
    ['riskIntelligenceHub', '/risk-intelligence/hub'],

    ...qualityToolsNavigationMap,

    ['interimDashboard', '/interim-dashboard'],
    ['search', '/search'],
    ['riskIntelligence', '/risk-intelligence'],
    ['riskIntelligenceAdmin', '/risk-intelligence/admin'],
    ['explorer', '/explorer'],
    ['connectData', '/data-management/maintain-data'],
    ['manageAccounts', '/data-management/manage-accounts'],
    ['documents', '/documents'],
    ['notifications', '/notifications'],
    ['settings', '/settings'],
    ['upload', '/upload'],

    // Calibrate
    ['reportBuilder', '/calibrate/report-builder'],
    ['reportBuilderControls', '/calibrate/report-builder-controls'],
    ['reportBuilderAcinControls', '/calibrate/report-builder-acin-controls'],
    ['reportBuilderControlQuality', '/calibrate/report-builder-control-quality'],
    ['reportBuilderNetworkControls', '/calibrate/report-builder-network-controls'],
]);

// Reverse map for looking up the id from the path
export const reverseRoutingMap = new Map([...routingMap].map((item) => [item[1], item[0]]));

export const getPathById = (id: string) => routingMap.get(id);
export const getIdByPath = (path: string) => reverseRoutingMap.get(path);
